export default [
    {
        path: '/',
        name: 'base',
        component: () => import('@/components/layout/main.vue'),
        children: [
            // 首页
            {
                path: '/',
                name: 'index',
                component: () =>import('@/components/index.vue')
            },
            // 项目管理
            {
                path: '/project/index',
                name: 'project',
                component: () =>import('@/components/project/index.vue')
            },
            {
                path: '/project/project',
                name: 'project.project',
                component: () =>import('@/components/project/project.vue')
            },
            {
                path: '/project/budget',
                name: 'project.budget',
                component: () =>import('@/components/project/budget-template.vue')
            },
            {
                path: '/project/pay',
                name: 'project.pay',
                component: () =>import('@/components/project/pay.vue')
            },
            {
                path: '/project/cost',
                name: 'project.cost',
                component: () =>import('@/components/project/cost.vue')
            },
            {
                path: '/project/person',
                name: 'project.person',
                component: () =>import('@/components/project/person.vue')
            },
            {
                path: '/project/impress',
                name: 'project.impress',
                component: () =>import('@/components/project/impress.vue')
            },
            {
                path: '/project/balance',
                name: 'project.balance',
                component: () =>import('@/components/project/balance.vue')
            },
            // 笔记管理
            {
                path: '/note/square',
                name: 'note.square',
                component: () =>import('@/components/note/square.vue')
            },
            {
                path: '/note/group',
                name: 'note.group',
                component: () =>import('@/components/note/group.vue')
            },
            // 通知消息管理
            {
                path: '/message/check',
                name: 'message.check',
                component: () =>import('@/components/message/check.vue')
            },
            {
                path: '/message/check/task',
                name: 'message.check.task',
                component: () =>import('@/components/message/check-task.vue'),
                meta: {
                    active: '/message/check',
                }
            },
            {
                path: '/message/check/:id',
                name: 'message.check.show',
                component: () =>import('@/components/message/check-show.vue'),
                meta: {
                    active: '/message/check',
                }
            },
            {
                path: '/message/group',
                name: 'message.group',
                component: () =>import('@/components/message/group.vue')
            },
            {
                path: '/message/logs',
                name: 'message.logs',
                component: () =>import('@/components/message/logs.vue')
            },
            {
                path: '/message/send',
                name: 'message.send',
                component: () =>import('@/components/message/send.vue')
            },
            // 统计
            {
                path: '/numbers/index',
                name: 'numbers.index',
                component: () =>import('@/components/numbers/index.vue')
            },
            // 待收款项目
            {
                path: '/numbers/padding-pay',
                name: 'numbers.padding-pay',
                component: () =>import('@/components/numbers/padding-pay.vue')
            },
            // 待续费项目
            {
                path: '/numbers/padding-renew',
                name: 'numbers.padding-renew',
                component: () =>import('@/components/numbers/padding-renew.vue')
            },
            {
                path: '/tc/index',
                name: 'tc.index',
                component: () => import('@/components/tc/index.vue')
            },
            {
                path: '/tc/person',
                name: 'tc.person',
                component: () => import('@/components/tc/person.vue')
            },
            {
                path: '/tc/detail',
                name: 'tc.detail',
                component: () => import('@/components/tc/detail.vue')
            },
            {
                path: '/task/index',
                name: 'task.index',
                component: () => import('@/components/task/index.vue')
            },
            {
                path: '/task/edit',
                name: 'task.edit',
                component: () => import('@/components/task/edit.vue')
            },
            {
                path: '/task/record',
                name: 'task.record',
                component: () => import('@/components/task/record.vue')
            },
            {
                path: '/task/record/import',
                name: 'task.record.import',
                component: () => import('@/components/task/record-import.vue')
            },
            {
                path: '/task/audit',
                name: 'task.audit',
                component: () => import('@/components/task/audit.vue')
            },
            {
                path: '/task/merchant',
                name: 'task.merchant',
                component: () => import('@/components/task/merchant.vue')
            },
            {
                path: '/member/index',
                name: 'member.index',
                component: () => import('@/components/member/index.vue')
            }
        ]
    },
    {
        path: '/auth/login',
        name: 'login',
        component: () =>import('@/components/auth/login.vue'),
    },
    {
        path: '/auth/logout',
        name: 'logout',
        component: () =>import('@/components/auth/logout.vue'),
    },
    {
        path: '/auth/socialite/:platform',
        name: 'socialite.login',
        component: () =>import('@/components/auth/socialite.vue'),
    },
    // 分享链接的地址
    {
        path: '/share',
        name: 'share',
        component: () =>import('@/components/share/base.vue'),
        children: [
            {
                path: '/share/check/data',
                name: 'share.check.data',
                component: () =>import('@/components/share/wechat/check-data.vue'),
            },
            {
                path: '/share/check/hexiao',
                name: 'share.check.hexiao',
                component: () =>import('@/components/share/wechat/check-hexiao.vue'),
            },
            {
                path: '/share/check/total',
                name: 'share.check.total',
                component: () =>import('@/components/share/wechat/check-total.vue'),
            },
            {
                path: '/share/note/:code',
                name: 'share.note.index',
                component: () =>import('@/components/share/note/index.vue'),
            },
            {
                path: '/share/check-task/:code',
                name: 'share.wechat.check-task',
                component: () => import('@/components/share/wechat/check-task-index.vue')
            },
            {
                path: '/share/project/padding-pay',
                name: 'share.project.padding-pay',
                component: () => import('@/components/share/project/padding-pay.vue')
            },
            {
                path: '/share/project/padding-renew',
                name: 'share.project.padding-renew',
                component: () => import('@/components/share/project/padding-renew.vue')
            },
            {
                path: '/share/project/numbers',
                name: 'share.project.numbers',
                component: () => import('@/components/share/project/numbers.vue')
            },
        ]
    },
    {
        path: '/wework',
        name: 'wework',
        component: () =>import('@/components/wework/base.vue'),
        children: [
            {
                path: '/wework/project',
                name: 'wework.project',
                component: () => import('@/components/wework/project.vue')
            },
            {
                path: '/wework/person',
                name: 'wework.person',
                component: () => import('@/components/wework/person.vue')
            },
            {
                path: '/wework/impress',
                name: 'wework.impress',
                component: () => import('@/components/wework/impress.vue')
            },
            {
                path: '/wework/contract',
                name: 'wework.contract',
                component: () => import('@/components/wework/contract.vue')
            }
        ]
    }
]
